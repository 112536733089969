<template>
<div class="content-wrapper overflow-visible">
  <patient-info :patient-data="patientData" :loading="loading.patientInfo" :use-history-data="true" :reg-id="$route.params.pageSlug" /> 

  <div class="content">
    <template v-if="isListDetail">
      <div class="page-title pt-0 pb-3">
        <router-link :to="{ name: 'RanapPasienPerawatan' }" class="text-dark">
          <i class="icon-arrow-left52 mr-1"></i>
          <span class="font-weight-semibold">Kembali</span>
        </router-link>
      </div>

      <div class="row g-2">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="d-flex align-items-center">
                <h5 class="card-title font-weight-semibold mr-auto">Permintaan Makan Pasien</h5>

                <div class="buttons d-flex">
                  <a href="javascript:;" v-if="moduleRole('add') && tData.page.total_data < 1" @click="openAdd()" class="btn bg-blue-600 btn-labeled btn-labeled-left mr-2">
                    <b><i class="icon-plus2"></i></b>Tambah Diit
                  </a>

                  <a href="javascript:;" v-if="moduleRole('hentikan_diit') && showStopDiitButton" @click="openStop()" class="btn bg-danger-600 btn-labeled btn-labeled-left">
                    <b><i class="icon-stop"></i></b>Hentikan Pemberian Makan
                  </a>
                </div>
              </div>
            </div>

            <div class="card-body">
              <table class="table table-bordered table-sm patient-table">
                <thead>
                  <tr>
                    <th>No.</th>
                    <th>Tanggal/Jam</th>
                    <th>Catatan</th>
                    <th>Diit Pagi</th>
                    <th>Diit Siang</th>
                    <th>Diit Sore</th>
                    <th>Aksi</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(v, k) in tData.data" :key="`datas-${k}`">
                    <td>
                      <span class="font-weight-semibold" v-b-tooltip.hover title="Lihat Detail">{{ k+1 }}</span>
                    </td>
                    <td>
                      {{ (v.created_date) | moment('LLL') }}

                      <br />

                      <div class="mt-2" v-if="v.is_stop">
                        <b-badge show variant="danger">Berhenti</b-badge>

                        <p class="mb-0">
                          <small>Diberhentikan Oleh: </small> <strong>{{ v.staff_stop }}</strong>
                        </p>
                      </div>
                    </td>
                    <td>{{ v.note || '-' }}</td>
                    <td>{{ v.diit_pagi || '-' }}</td>
                    <td>{{ v.diit_siang || '-' }}</td>
                    <td>{{ v.diit_sore || '-' }}</td>
                    <td>
                      <a href="javascript:;" class="btn btn-icon alpha-blue border-blue mr-2" v-b-tooltip.hover title="Lihat Detail"><i class="icon-file-eye" @click="openDetail(v)"></i></a>

                      <a href="javascript:;" @click="openEdit(v.id)" class="btn btn-icon alpha-warning border-warning mr-2" v-b-tooltip.hover title="Edit" v-if="moduleRole('edit') && showStopDiitButton"><i class="icon-pencil4"></i></a>

                      <!-- <a href="javascript:;" @click="openEdit(v.id)" class="btn btn-icon alpha-warning border-warning" v-b-tooltip.hover title="Hentikan Diit" v-if="moduleRole('hentikan_diit')"><i class="icon-stop"></i></a> -->
                    </td>
                  </tr>

                  <tr v-if="tData.page.total_data == 0">
                    <td colspan="10">
                      <div class="p-2 text-center">
                        <h5 class="mb-0">Belum ada permintaan makan pasien</h5>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="d-flex justify-content-end" v-if="tData.page.total_data > tData.page.per_page">
              <b-pagination class="mb-0" v-model="pageNoTData" :per-page="tData.page.per_page" :total-rows="tData.page.total_data" />
            </div>
          </div>
        </div>

        <b-modal id="detailData" size="md" hide-footer title="Detail Diit">
          <div class="row">
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-6">
                  <span class="font-weight-bold">Bentuk Makanan</span>
                  <p>{{ dataModal.bentuk_makanan }}</p>
                </div>

                <div class="col-md-6">
                  <span class="font-weight-bold">Jenis Diit</span>
                  <ol class="pl-2">
                    <li v-for="(v,k) in dataModal.jenis_diit" :key="`jenis-${k}`">{{ v }}</li>
                  </ol>
                </div>

                <div class="col-md-6">
                  <span class="font-weight-bold">Nama Petugas</span>
                  <p>{{ dataModal.nama_petugas }}</p>
                </div>

                <div class="col-md-6">
                  <span class="font-weight-bold">Catatan</span>
                  <p>{{ dataModal.note||'-' }}</p>
                </div>

                <div class="col-md-12 mt-3">
                  <div class="table-responsive">
                    <table class="table">
                      <thead>
                        <tr>
                          <th>Pagi ({{ configTime.pagi }})</th>
                          <th>Siang ({{ configTime.siang }})</th>
                          <th>Sore ({{ configTime.sore }})</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{{ dataModal.diit_pagi || '-' }}</td>
                          <td>{{ dataModal.diit_siang || '-' }}</td>
                          <td>{{ dataModal.diit_sore || '-' }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-modal>

        <b-modal id="formData" size="lg" hide-footer :title="dataModal.title">
          <validation-observer ref="VFormDiit">
            <b-form @submit.prevent="doSubmit()">
              <div class="row">
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-md-6">
                      <label>Bentuk Makanan <span class="text-danger">*</span></label>
                      <v-select :options="mrBentukMakanan" label="label" v-model="row.bentuk_makanan" @input="setDiit()" />

                      <VValidate name="Bentuk Makanan" v-model="row.bentuk_makanan" :rules="mrValidation.bentuk_makanan" />
                    </div>

                    <div class="col-md-6">
                      <label>Jenis Diit <span class="text-danger">*</span></label>
                      <v-select :options="mrJenisDiit" label="label" v-model="row.jenis_diit" class="vs__multiple" multiple />

                      <VValidate name="Jenis Diit" v-model="row.jenis_diit" :rules="mrValidation.jenis_diit" />
                    </div>

                    <div class="col-md-12 mt-3">
                      <div class="row align-items-center">
                        <div class="col-md-3 align-items-center">
                          <b-form-checkbox v-model="row.is_puasa" value="Y" unchecked-value="N">
                            Pasien Puasa?
                          </b-form-checkbox>
                        </div>

                        <div class="col-md-4" v-if="row.is_puasa == 'Y'">
                          <v-select :options="mrPuasaSessions" :reduce="v => v.value" label="label" v-model="row.puasa_sessions" class="vs__multiple" multiple placeholder="-- Waktu Puasa --" />

                          <VValidate name="Puasa" v-model="row.puasa_sessions" :rules="{ required: 1 }" />
                        </div>

                        <div class="col-md-5" v-if="row.is_puasa == 'Y'">
                          <div class="input-group">
                            <datepicker input-class="form-control transparent"
                            placeholder="Pilih Tanggal Puasa" class="my-datepicker"
                            calendar-class="my-datepicker_calendar" v-model="row.puasa_date">
                            </datepicker>
                            <div class="input-group-append calendar-group">
                            <span class="input-group-text" id="basic-addon2"><i
                                class="icon-calendar"></i></span>
                            </div>
                          </div>
                        </div>

                        <template v-if="row.is_puasa == 'Y'">
                          <div class="col-md-3"> </div>
                          <div class="col-md-9 mt-3">
                            <label>Catatan Puasa</label>
                            <b-form-textarea
                              v-model="row.puasa_note"
                              placeholder="Catatan Puasa"
                              :formatter="normalText"
                            >

                            </b-form-textarea>
                          </div>
                        </template>
                      </div>


                    </div>


                    <div class="col-md-12 mt-3">
                      <label>Catatan</label>
                      <b-form-textarea v-model="row.note" placeholder="Catatan" :formatter="normalText">

                      </b-form-textarea>
                    </div>

                    <div class="col-md-12 mt-3">
                      <table class="table">
                        <thead>
                          <tr>
                            <th>Tanggal</th>
                            <th>Pagi ({{ configTime.pagi }})</th>
                            <th>Siang ({{ configTime.siang }})</th>
                            <th>Sore ({{ configTime.sore }})</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-if="date2String(addDaysToDate(1)) != date2String(new Date(row.created_date))">
                            <td>{{ new Date() | moment('LL') }}</td>
                            <td>
                              <template v-if="date2String(new Date()) == row.puasa_date">

                                <span v-if="(row.puasa_sessions||[]).indexOf('PAGI') < 0 && date2String(new Date()) == row.puasa_date">
                                  <template v-if="isAboveTime(configTime.pagi)">
                                    <b-form-checkbox 
                                      value="Y"
                                      unchecked-value="N"
                                      v-model="row.unlock_diit_pagi"
                                    >
                                      Berikan Diit Pagi
                                    </b-form-checkbox>
                                  </template>

                                  <template v-else>
                                    {{ (row.bentuk_makanan || '-') }}
                                  </template>
                                </span>

                                <span v-else>
                                  <v-select 
                                    :options="mrBentukMakanan" class="mt-2"
                                    label="label" v-model="row.diit_pagi" 
                                    v-if="row.bentuk_makanan" />
                                </span>
                              </template>

                              <span v-else>
                                <template v-if="isAboveTime(configTime.pagi)">
                                  <b-form-checkbox 
                                    value="Y"
                                    unchecked-value="N"
                                    v-model="row.unlock_diit_pagi"
                                  >
                                    Berikan Diit Pagi
                                  </b-form-checkbox>

                                  <v-select 
                                    :options="mrBentukMakanan" class="mt-2"
                                    label="label" v-model="row.diit_pagi" 
                                    v-if="row.bentuk_makanan && row.unlock_diit_pagi == 'Y'" />
                                </template>

                                <template v-else>
                                  <v-select 
                                    :options="mrBentukMakanan" class="mt-2"
                                    label="label" v-model="row.diit_pagi" 
                                    v-if="row.bentuk_makanan" />
                                </template>
                                <!-- {{ isAboveTime(configTime.pagi) ? '-' : (row.bentuk_makanan || '-') }} -->

                              </span>
                            </td>
                            <td>
                              <template v-if="date2String(new Date()) == row.puasa_date">
                                <span v-if="(row.puasa_sessions||[]).indexOf('SIANG') < 0 && date2String(new Date()) == row.puasa_date">
                                  {{ isAboveTime(configTime.siang) ? '-' : (row.bentuk_makanan || '-') }}
                                </span>
                                <span v-else>-</span>
                              </template>

                              <span v-else>
                                <template v-if="isAboveTime(configTime.siang)">
                                  <b-form-checkbox 
                                    value="Y"
                                    unchecked-value="N"
                                    v-model="row.unlock_diit_siang"
                                  >
                                    Berikan Diit Siang
                                  </b-form-checkbox>

                                  <v-select 
                                    :options="mrBentukMakanan" class="mt-2"
                                    label="label" v-model="row.diit_siang" 
                                    v-if="row.bentuk_makanan && row.unlock_diit_siang == 'Y'" />
                                </template>

                                <template v-else>
                                  <v-select 
                                    :options="mrBentukMakanan" class="mt-2"
                                    label="label" v-model="row.diit_siang"
                                    v-if="row.bentuk_makanan" />
                                </template>
                              </span>
                            </td>
                            <td>
                              <template v-if="date2String(new Date()) == row.puasa_date">
                                <span v-if="(row.puasa_sessions||[]).indexOf('SORE') < 0 && date2String(new Date()) == row.puasa_date">
                                  {{ isAboveTime(configTime.sore) ? '-' : (row.bentuk_makanan || '-') }}
                                </span>

                                <span v-else>-</span>
                              </template>

                              <span v-else>
                                <template v-if="isAboveTime(configTime.sore)">
                                  <b-form-checkbox 
                                    value="Y"
                                    unchecked-value="N"
                                    v-model="row.unlock_diit_sore"
                                  >
                                    Berikan Diit Sore
                                  </b-form-checkbox>

                                  <v-select 
                                    :options="mrBentukMakanan" class="mt-2"
                                    label="label" v-model="row.diit_sore" 
                                    v-if="row.bentuk_makanan && row.unlock_diit_sore == 'Y'" />
                                </template>

                                <template v-else>
                                  <v-select 
                                    :options="mrBentukMakanan" class="mt-2"
                                    label="label" v-model="row.diit_sore" 
                                    v-if="row.bentuk_makanan" />
                                </template>
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td>{{ addDaysToDate(1) | moment('LL') }}</td>
                            <td>
                              <template v-if="date2String(addDaysToDate(1)) == row.puasa_date">
                                <span v-if="(row.puasa_sessions||[]).indexOf('PAGI') < 0">
                                  <v-select 
                                    :options="mrBentukMakanan" class="mt-2"
                                    label="label" v-model="row.next_diit_pagi" 
                                    v-if="row.bentuk_makanan" />
                                </span>

                                <span v-else>-</span>
                              </template>

                              <span v-else>
                                <v-select 
                                  :options="mrBentukMakanan" class="mt-2"
                                  label="label" v-model="row.next_diit_pagi" 
                                  v-if="row.bentuk_makanan" />
                              </span>
                            </td>
                            <td>
                              <template v-if="date2String(addDaysToDate(1)) == row.puasa_date">
                                <span v-if="(row.puasa_sessions||[]).indexOf('SIANG') < 0">
                                  <v-select 
                                    :options="mrBentukMakanan" class="mt-2"
                                    label="label" v-model="row.next_diit_siang" 
                                    v-if="row.bentuk_makanan" />
                                </span>

                                <span v-else>-</span>
                              </template>

                              <span v-else>
                                <v-select 
                                  :options="mrBentukMakanan" class="mt-2"
                                  label="label" v-model="row.next_diit_siang" 
                                  v-if="row.bentuk_makanan" />
                              </span>
                            </td>
                            <td>
                              <template v-if="date2String(addDaysToDate(1)) == row.puasa_date">
                                <span v-if="(row.puasa_sessions||[]).indexOf('SORE') < 0">
                                  <v-select 
                                    :options="mrBentukMakanan" class="mt-2"
                                    label="label" v-model="row.next_diit_sore" 
                                    v-if="row.bentuk_makanan" />
                                </span>

                                <span v-else>-</span>
                              </template>

                              <span v-else>
                                <v-select 
                                  :options="mrBentukMakanan" class="mt-2"
                                  label="label" v-model="row.next_diit_sore" 
                                  v-if="row.bentuk_makanan" />
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div class="col-md-12 mt-3">
                      <div class="text-right">
                        <button class="btn" type="button" @click="$bvModal.hide('formData')">Batal</button>
                        <button type="submit" class="btn btn-labeled btn-labeled-left btn-success">
                          <b><i class="icon-checkmark"></i></b>
                          <span>Simpan</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </b-form>
          </validation-observer>
        </b-modal>

        <b-modal id="stopDiit" size="md" hide-footer title="Hentikan Diit">          
          <div class="row">
            <div class="col-md-6" :class="{'col-md-6': date2String(row.date) != date2String(new Date()), 'col-md-12': date2String(row.date) == date2String(new Date()) && (mrWaktuDiit||[]).length < 1}">
              <div class="form-group">
                <label>Waktu Berhenti</label>
                <div class="input-group">
                  <datepicker input-class="form-control transparent"
                    placeholder="Pilih Tanggal Puasa" class="my-datepicker"
                    calendar-class="my-datepicker_calendar" v-model="row.date"
                    :disabled-dates="disabledDateStopDiit"
                  >
                  </datepicker>
                  <div class="input-group-append calendar-group">
                  <span class="input-group-text" id="basic-addon2"><i
                      class="icon-calendar"></i></span>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-6" v-if="
              (date2String(row.date) != date2String(new Date())) || (date2String(row.date) == date2String(new Date()) && (mrWaktuDiit||[]).length > 0)
            ">
              <div class="form-group">
                <label class="text-white">Waktu</label>
                <v-select :options="date2String(row.date) != date2String(new Date()) ? mrPuasaSessions : mrWaktuDiit" :reduce="v => v.value" label="label" v-model="row.sessions" class="vs__multiple" multiple placeholder="-- Waktu Diit --" />
              </div>
            </div>

            <div class="col-md-12">
              <b-alert show variant="danger">
                Dengan menyimpan data ini, Anda yakin bahwa perawatan Pasien sudah selesai dan pemberian makan/diit Pasien dihentikan mulai dari waktu diit yang Anda pilih di atas.
              </b-alert>
            </div>

            <div class="col-md-12">
              <div class="text-right">
                <button type="submit" class="btn btn-labeled btn-labeled-left btn-success" @click="stopDiit()">
                  <b><i class="icon-checkmark"></i></b>
                  <span>Simpan</span>
                </button>
              </div>
            </div>
          </div>
        </b-modal>

      </div>
    </template>
    <template v-else>
      <Form />
    </template>
  </div>
</div>
</template>

<script>
import PatientInfo from '@/components/Ranap/PatientInfo.vue'
import GlobalVue from '@/libs/Global.vue'
import $ from 'jquery'
const _ = global._
import Gen from '@/libs/Gen.js'
import Form from './Form.vue'

import Datepicker from 'vuejs-datepicker'

export default {
  extends: GlobalVue,
  components: {
    Form,
    PatientInfo,
    Datepicker
  },
  data() {
    return {
      patientData: {},
      loading: {
        patientInfo: false,
        coreData: false
      },

      rowReg: {},
      isMonitoring: false,
      modalConfig: {
        title: '',
        size: 'sm'
      },
      isModal: '',
      rowEdit: {},
      openModal: false,
      dataKajian: [],

      mrKegawatanPernafasan: [],
      mrTonusOtot: [],
      mrPerlambatanSirkulasi: [],
      mrPuasaSessions: [],
      mrWaktuDiit: [],
      mrFaktorGejalaFisik: [],
      mrManajemenGejala: [],
      mrReaksiPasienAsesmen: [],
      mrReaksiPasienKeperawatan: [],
      mrReaksiKeluargaAsesmen: [],
      mrReaksiKeluargaKeperawatan: [],
      mrKebutuhanDukungan: [],
      mrKebutuhanAlternatif: [],
      mrResikoKeluargaAsesmen: [],
      mrResikoKeluargaKeperawatan: [],
      mrHubunganKeluarga: [],
      mrNextCarePlan: [],

      mrValidation: {},

      tData: {
        data: [],
        page: {
          total_data: 0,
          per_page: 10,
          current_page: 1,
          is_loading: false
        }
      },

      pageNoTData: 1,

      row: {},
      dataModal: {},
      configTime: {},
      mrJenisDiit: [],
      mrBentukMakanan: [],

      isAddMonitoring: false,
      showStopDiitButton: false,
      disabledDateStopDiit: {
        to: new Date(),
      }
    }
  },
  computed: {
    isListDetail() {
      return !this.$route.query.viewType
    },
    viewType() {
      return this.$route.query.viewType
    },
  },
  methods: {
    stopDiit(){
      this.$swal.fire({
        title: 'Apakah Anda Yakin Untuk Memberhentikan Diit?',
        cancelButtonText: "Batal",
        confirmButtonText: "Ya, Hentikan",
        showConfirmButton: true,
        showCancelButton: true,

        icon: 'warning',

      }).then(button => {
        if(button.isConfirmed){
          this.$swal.fire({
            title: 'Loading...',
            showConfirmButton: false,
            allowOutsideClick: false,
            didOpen: () => {
              this.$swal.showLoading()
            }
          })

          Gen.apiRest(
            "/do/" + this.modulePage, {
              data: {
                ...this.row,
                type: 'hentikan-pemberian-makan',
                id_registrasi: this.$route.params.pageSlug
              }
            },
            "POST"
          ).then(resp => {
            this.$swal.close()
            this.$swal({
              title: resp.data.message,
              icon: 'success',
              confirmButtonText: 'Ok'
            }).then(result => {
              if (result.value) {
                this.getList()
                this.$bvModal.hide("stopDiit")

              }
            })
          }).catch(() => {
            this.$swal.close()

            return this.$swal({
              icon: 'error',
              text: 'Terjadi Kesalahan Yang Tidak Diketahui'
            })
          })

        }
      })
    },
    openStop(){
      this.$set(this, 'dataModal', {
        title: "Tambah Diit",
        type: "add-pemberian-makan"  
      })

      this.$bvModal.show('stopDiit')
      this.getForm(null, false)
    },
    setDiit(){
      this.$set(this, 'row', {
        ...this.row,
        diit_pagi: this.row.bentuk_makanan,
        diit_siang: this.row.bentuk_makanan,
        diit_sore: this.row.bentuk_makanan,
        next_diit_pagi: this.row.bentuk_makanan,
        next_diit_siang: this.row.bentuk_makanan,
        next_diit_sore: this.row.bentuk_makanan,
      })
    },
    date2String(date){
      if(!date) return ``
      return `${date.getFullYear()}-${(date.getMonth()+1).toString().padStart(2, '0')}-${date.getDate()}`
    },
    isAboveTime(targetTime){
      const targetDate = new Date()
      const [hours, minutes] = (targetTime||"00:00").split(":")

      targetDate.setHours(parseInt(hours, 10), parseInt(minutes, 10), 0, 0)

      const currentDate = new Date()
      return currentDate.getTime() > targetDate.getTime()
    },
    addDaysToDate(diff){
      const date = new Date()
      date.setDate(date.getDate() + diff)

      return date
    },
    decreaseDaysToDate(diff){
      const date = new Date()
      date.setDate(date.getDate() - diff)

      return date
    },
    openDetail(data) {
      this.$bvModal.show('detailData')

      Gen.apiRest(
        "/get/" + this.modulePage, {
          params: {
            type: "get-detail",
            id_pemberian_makan: data.aranpm_id
          }
        },
      ).then(res => {
        this.$set(this, 'dataModal', {
          ...data,
          ...res.data.data
        })

        this.$set(this, 'configTime', res.data.configTime)
      })
    },
    openAdd(){
      this.$set(this, 'dataModal', {
        title: "Tambah Diit",
        type: "add-pemberian-makan"  
      })

      this.getForm(null)
    },
    openEdit(id){
      this.$set(this, 'dataModal', {
        title: "Edit Diit",
        type: "update-pemberian-makan"  
      })

      this.getForm(id)
    },


    getPatientInfo() {
      this.$set(this.loading, 'patientInfo', true)
      Gen.apiRest(
        "/do/" + 'RanapPasienPerawatan', {
          data: {
            type: "get-data-pasien",
            id_registrasi: this.$route.params.pageSlug
          }
        },
        "POST"
      ).then(resp => {
        this.$set(this, 'patientData', resp.data.data)

        this.$set(this.loading, 'patientInfo', false)
      })
    },

    getList() {
      Gen.apiRest(
        "/get/" + this.modulePage, {
          params: {
            type: "get-list",
            id_registrasi: this.$route.params.pageSlug
          }
        },
      ).then(res => {
        _.forEach(res.data, (v, k) => {
          this.$set(this, k, v)
        })

        this.$set(this, 'loadingOverlay', false)
      })
    },

    getForm(id, showFormData=true) {
      if(showFormData) this.$bvModal.show('formData')

      Gen.apiRest(
        "/get/" + this.modulePage, {
          params: {
            type: "get-form",
            id_pemberian_makan: id
          }
        },
      ).then(res => {
        _.forEach(res.data, (v, k) => {
          this.$set(this, k, v)
        })
        
        this.$set(this, 'disabledDateStopDiit', {
          from: new Date(Date.parse(res.data.disabledDateStop.from)),
          to: new Date(Date.parse(res.data.disabledDateStop.to)),
        })
      })
    },

    doSubmit() {
      this.$swal.fire({
        title: 'Loading...',
        showConfirmButton: false,
        allowOutsideClick: false,
        didOpen: () => {
          this.$swal.showLoading()
        }
      })

      Gen.apiRest(
        "/do/" + this.modulePage, {
          data: {
            ...this.row,
            type: this.dataModal.type,
            id_registrasi: this.$route.params.pageSlug
          }
        },
        "POST"
      ).then(resp => {
        this.$swal.close()
        this.$swal({
          title: resp.data.message,
          icon: 'success',
          confirmButtonText: 'Ok'
        }).then(result => {
          if (result.value) {
            this.getList()
            this.$bvModal.hide("formData")

          }
        })
      }).catch(() => {
        this.$swal.close()

        return this.$swal({
          icon: 'error',
          text: 'Terjadi Kesalahan Yang Tidak Diketahui'
        })
      })
    }
  },
  mounted() {
    this.getPatientInfo()
    this.getList()
  },
  watch: {
    $route() {
      this.getPatientInfo()
      this.getList()
    },
    'row.is_puasa'(v){
      if(v == 'N') this.$set(this, 'row', { ...this.row, puasa_sessions: [], puasa_date: null })
    },
  }
}
</script>
