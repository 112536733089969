<template>
<div class="card">
  <div class="card-header">
    <h6 class="card-title font-weight-semibold">Form Terminal</h6>
  </div>
  <validation-observer ref="VForm">
    <b-form @submit.prevent="doSubmit()">
        <div class="form-underlying card-body">
        <div>
            <div class="row">
            <div class="col-md-12">
                <div class="card">
                <div class="card-header bg-info">
                    <h5 class="card-title font-weight-semibold">1. Gejala seperti mau muntah dan kesulitan bernafas
                    </h5>
                </div>
                <div class="card-body">
                    <div class="row">
                    <div class="col-md-6">
                        <div class="card">
                        <div class="card-header bg_head_panel">
                            <h6 class="card-title font-weight-semibold">1.1 Kegawatan Pernafasan</h6>
                        </div>
                        <div class="card-body">
                            <div class="form-group mt-3">
                            <b-form-checkbox-group v-model="row.kegawatan_pernafasan" name="`metode`">
                                <b-form-checkbox v-for="(v, k) in $parent.mrKegawatanPernafasan" :key="k" :value="v" :id="`kegawatan${k}`" class="mb-2">
                                {{ v.label }}
                                </b-form-checkbox>
                            </b-form-checkbox-group>

                            <VValidate name="Kegawatan Pernafasan" :value="(row.kegawatan_pernafasan||[]).length > 0 ? true : null" :rules="mrValidation.kegawatan_pernafasan" />
                            </div>
                        </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="card">
                        <div class="card-header bg_head_panel">
                            <h6 class="card-title font-weight-semibold">1.2 Kehilangan tonus otot</h6>
                        </div>
                        <div class="card-body">
                            <div class="form-group mt-3">
                            <b-form-checkbox-group v-model="row.kehilangan_tonus_otot" name="`metode`">
                                <b-form-checkbox v-for="(v, k) in $parent.mrTonusOtot" :key="k" :value="v" :id="`kehilangan_tonus_otot${k}`" class="mb-2">
                                {{ v.label }}
                                </b-form-checkbox>
                            </b-form-checkbox-group>

                            <VValidate name="Kehilangan tonus obat" :value="(row.kehilangan_tonus_otot||[]).length > 0 ? true : null" :rules="mrValidation.kehilangan_tonus_otot" />
                            </div>
                        </div>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="card">
                        <div class="card-header bg_head_panel">
                            <h6 class="card-title font-weight-semibold">1.3 Nyeri</h6>
                        </div>
                        <div class="card-body">
                            <div class="form-group mt-3">
                            <b-form-radio-group :options="Config.mr.StatusOptions" v-model="row.nyeri" />

                            <VValidate name="Nyeri" v-model="row.nyeri" :rules="mrValidation.nyeri" />
                            </div>
                        </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="card">
                        <div class="card-header bg_head_panel">
                            <h6 class="card-title font-weight-semibold">1.4 Perlambatan sirkulasi</h6>
                        </div>
                        <div class="card-body">
                            <div class="form-group mt-3">
                            <b-form-checkbox-group v-model="row.perlambatan_sirkulasi" name="`metode`">
                                <b-form-checkbox v-for="(v, k) in $parent.mrPerlambatanSirkulasi" :key="k" :value="v" :id="`perlambatan_sirkulasi${k}`" class="mb-2">
                                {{ v.label }}
                                </b-form-checkbox>
                            </b-form-checkbox-group>

                            <VValidate name="Perlambatan sirkulasi" :value="(row.perlambatan_sirkulasi||[]).length > 0 ? true : null" :rules="mrValidation.perlambatan_sirkulasi" />
                            </div>
                        </div>
                        </div>
                    </div>

                    </div>
                </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="card">
                <div class="card-header bg-info">
                    <h5 class="card-title font-weight-semibold">2. Faktor yang meningkatkan dan membangkitkan gejala
                    fisik</h5>
                </div>
                <div class="card-body">
                    <div class="form-group mt-3">
                    <b-form-checkbox-group v-model="row.faktor_gejala_fisik" name="`metode`">
                        <b-form-checkbox v-for="(v, k) in $parent.mrFaktorGejalaFisik" :key="k" :value="v" :id="`faktor_gejala_fisik${k}`" class="mb-2">
                        <div :class="{'d-flex': checkCheckedValue(v.value, row.faktor_gejala_fisik) && v.value == 'OTHER'}">
                            <span>{{ v.label }}</span>
                            <div v-if="checkCheckedValue(v.value, row.faktor_gejala_fisik)" class="ml-3">
                            <template v-if="v.value == 'OTHER'">
                                <b-form-textarea rows="3" cols="50" v-model="v.note" />
                                <VValidate name="Catatan" v-model="v.note" :vid="`mjg-${k}`" rules="required" />
                            </template>
                            </div>
                        </div>
                        </b-form-checkbox>
                    </b-form-checkbox-group>

                    <VValidate name="Faktor yang meningkatkan dan membangkitkan gejala fisik" :value="(row.faktor_gejala_fisik||[]).length > 0 ? true : null" :rules="mrValidation.faktor_gejala_fisik" />
                    </div>
                </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="card">
                <div class="card-header bg-info">
                    <h5 class="card-title font-weight-semibold">3. Manajemen gejala saat ini dan respon pasien</h5>
                </div>
                <div class="card-body">
                    <label>Masalah perawatan</label>
                    <b-form-checkbox-group v-model="row.manajemen_gejala" name="`metode`">
                    <b-form-checkbox v-for="(v, k) in $parent.mrManajemenGejala" :key="k" :value="v" :id="`manajemen_gejala${k}`" class="mb-2">
                        <div :class="{'d-flex': checkCheckedValue(v.value, row.manajemen_gejala) && v.value == 'OTHER'}">
                        <span>{{ v.label }}</span>
                        <div v-if="checkCheckedValue(v.value, row.manajemen_gejala)" class="ml-3">
                            <template v-if="v.value == 'OTHER'">
                            <b-form-textarea rows="3" cols="50" v-model="v.note" />
                            <VValidate name="Catatan" v-model="v.note" :vid="`mjg-${k}`" rules="required" />
                            </template>
                        </div>
                        </div>
                    </b-form-checkbox>
                    </b-form-checkbox-group>

                    <VValidate name="Manajemen gejala saat ini dan respon pasien" :value="(row.manajemen_gejala||[]).length > 0 ? true : null" :rules="mrValidation.manajemen_gejala" />
                </div>
                </div>
            </div>

            <div class="col-md-6">
                <div class="card">
                <div class="card-header bg-info">
                    <h5 class="card-title font-weight-semibold">4. Orientasi spiritual pasien dan keluarga</h5>
                </div>
                <div class="card-body">
                    <label>Apakah perlu pelayanan spiritual?</label>
                    <div class="d-flex">
                    <b-form-radio-group :options="Config.mr.yesNoOptV4" v-model="row.orientasi_spiritual">
                    </b-form-radio-group>

                    <div class="ml-2" v-if="row.orientasi_spiritual == 'Y'">
                        <input type="text" class="form-control form-control-sm" placeholder="Oleh" v-model="row.orientasi_spiritual_note">

                        <VValidate name="Catatan" key="note_os" v-model="row.orientasi_spiritual_note" :rules="mrValidation.orientasi_spiritual_note" />
                    </div>
                    </div>

                    <VValidate name="Orientasi Spiritual" v-model="row.orientasi_spiritual" :rules="mrValidation.orientasi_spiritual" />
                </div>
                </div>
            </div>

            <div class="col-md-6">
                <div class="card">
                <div class="card-header bg-info">
                    <h5 class="card-title font-weight-semibold">5. Unsur dan kebutuhan spiritual pasien dan keluarga
                    seperti putus asa, penderitaan, rasa bersalah, atau pengampunan</h5>
                </div>
                <div class="card-body">
                    <div class="form-group">
                    <label>Perlu didoakan</label>
                    <div class="d-flex">
                        <b-form-radio-group :options="Config.mr.yesNoOptV4" v-model="row.need_to_pray">
                        </b-form-radio-group>

                        <div class="ml-2" v-if="row.need_to_pray == 'Y'">
                        <input type="text" class="form-control form-control-sm" placeholder="Oleh" v-model="row.need_to_pray_note">

                        <VValidate name="Catatan" key="note_ntp" v-model="row.need_to_pray_note"  :rules="{required: true}" />
                        </div>
                    </div>

                    <VValidate name="Perlu didoakan" v-model="row.need_to_pray" :rules="mrValidation.need_to_pray" />
                    </div>

                    <div class="form-group">
                    <label>Perlu bimbingan rohani</label>
                    <div class="d-flex">
                        <b-form-radio-group :options="Config.mr.yesNoOptV4" v-model="row.need_spiritual_guide">
                        </b-form-radio-group>

                        <div class="ml-2" v-if="row.need_spiritual_guide == 'Y'">
                        <input type="text" class="form-control form-control-sm" placeholder="Oleh" v-model="row.need_spiritual_guide_note">

                        <VValidate name="Catatan" key="note_pbr" v-model="row.need_spiritual_guide_note" :rules="{required: true}" />
                        </div>
                    </div>

                    <VValidate name="Perlu Bimbingan Rohani" v-model="row.need_spiritual_guide" :rules="mrValidation.need_spiritual_guide" />
                    </div>

                    <div class="form-group">
                    <label>Perlu pendampingan rohani</label>
                    <div class="d-flex">
                        <b-form-radio-group :options="Config.mr.yesNoOptV4" v-model="row.need_spiritual_assistance">
                        </b-form-radio-group>

                        <div class="ml-2" v-if="row.need_spiritual_assistance == 'Y'">
                        <input type="text" class="form-control form-control-sm" placeholder="Oleh" v-model="row.need_spiritual_assistance_note">

                        <VValidate name="Catatan" key="note_ppr" v-model="row.need_spiritual_assistance_note" :rules="{required: true}" />
                        </div>
                    </div>

                    <VValidate name="Perlu Pendampingan Rohani" v-model="row.need_spiritual_assistance" :rules="mrValidation.need_spiritual_assistance" />
                    </div>
                </div>
                </div>
            </div>

            <div class="col-md-12">
                <div class="card">
                <div class="card-header bg-info">
                    <h5 class="card-title font-weight-semibold">6. Status psikososial pasien dan keluarga
                    </h5>
                </div>
                <div class="card-body">
                    <div class="row">
                    <div class="col-md-6">
                        <div class="card">
                        <div class="card-header bg_head_panel">
                            <h6 class="card-title font-weight-semibold">6.1 Apakah ada orang yang ingin dihubungi
                            saat ini?</h6>
                        </div>
                        <div class="card-body">
                            <div class="form-group mt-3">
                            <div class="d-flex">
                                <b-form-radio-group :options="Config.mr.yesNoOptV4" v-model="row.contact_person">
                                </b-form-radio-group>

                                <template v-if="row.contact_person == 'Y'">
                                <div class="ml-2">
                                    <input type="text" class="form-control form-control-sm" placeholder="Siapa" v-model="row.contact_person_name">

                                    <VValidate name="Nama" key="cp_name" v-model="row.contact_person_name" :rules="mrValidation.contact_person_name" />
                                </div>

                                <div class="ml-2">
                                    <input type="text" class="form-control form-control-sm" placeholder="Di mana" v-model="row.contact_person_location">

                                    <VValidate name="Lokasi" key="cp_location" v-model="row.contact_person_location" :rules="mrValidation.contact_person_location" />
                                </div>
                                </template>
                            </div>

                            <VValidate name="Orang yang ingin dihubungi" v-model="row.contact_person" :rules="mrValidation.contact_person" />
                            </div>
                            
                            <template v-if="row.contact_person == 'Y'">
                                <div class="form-group">
                                <label>Hubungan dengan pasien sebagai <small class="txt_mandatory">*</small></label>
                                <v-select
                                    :options="$parent.mrHubunganKeluarga"
                                    v-model="row.contact_person_relation"
                                    :reduce="v => v.value"
                                    label="label"
                                />

                                <VValidate name="Hubungan Pasien" v-model="row.contact_person_relation" :rules="mrValidation.contact_person_relation" />
                                </div>

                                <div class="form-group">
                                <label>No Selular</label>
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                    <span class="input-group-text" id="basic-addon2">+62</span>
                                    </div>
                                    <b-form-input type="tel" :formatter="Formatter.phone" v-model="row.contact_person_phone" />
                                </div>

                                <VValidate name="No Selular" v-model="row.contact_person_phone" :rules="mrValidation.contact_person_phone" />
                                </div>
                            </template>
                        </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="card">
                        <div class="card-header bg_head_panel">
                            <h6 class="card-title font-weight-semibold">6.2 Bagaimana rencana perawatan selanjutnya?
                            </h6>
                        </div>
                        <div class="card-body">
                            <div class="form-group mt-3">
                            <div>
                                <b-form-radio-group :options="$parent.mrNextCarePlan" v-model="row.next_care_plan" text-field="label" value-field="value">
                                </b-form-radio-group>

                                <VValidate name="Rencana Perawatan" v-model="row.next_care_plan" :rules="mrValidation.next_care_plan" />

                                <template v-if="row.next_care_plan == 'DIRAWAT_DI_RUMAH'">
                                    <div class="form-group mt-3">
                                        <label>Apakah lingkungan rumah disiapkan?</label>
                                        <b-form-radio-group :options="Config.mr.yesNoOptV4" v-model="row.is_environment_prepared">
                                        </b-form-radio-group>

                                        <VValidate name="Lingkungan rumah" v-model="row.is_environment_prepared" :rules="mrValidation.is_environment_prepared" />
                                    </div>

                                    <div class="form-group">
                                        <label>Apakah ada yang mampu merawat pasien di rumah?</label>
                                        <b-form-radio-group :options="Config.mr.yesNoOptV4" v-model="row.is_any_people_care">
                                        </b-form-radio-group>

                                        <VValidate name="Merawat pasien di rumah" v-model="row.is_any_people_care" :rules="mrValidation.is_any_people_care" />
                                    </div>

                                    <div class="form-group">
                                        <label>Apakah perlu difasilitasi RS (Home Care)?</label>
                                        <b-form-radio-group :options="Config.mr.yesNoOptV4" v-model="row.is_need_home_care">
                                        </b-form-radio-group>

                                        <VValidate name="Perlu difasilitasi" v-model="row.is_need_home_care" :rules="mrValidation.is_need_home_care" />
                                    </div>
                                </template>

                            </div>
                            </div>
                        </div>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="card">
                        <div class="card-header bg_head_panel">
                            <h6 class="card-title font-weight-semibold">6.3 Reaksi pasien atas penyakitnya</h6>
                        </div>
                        <div class="card-body">
                            <div class="form-group mt-2">
                            <label>Asesmen informasi</label>
                            <b-form-checkbox-group v-model="row.reaksi_pasien_asesmen" name="`reaksi_pasien_asesmen`">
                                <b-form-checkbox v-for="(v, k) in $parent.mrReaksiPasienAsesmen" :key="k" :value="v" :id="`reaksi_pasien_asesmen${k}`" class="mb-2">
                                <div :class="{'d-flex': checkCheckedValue(v.value, row.reaksi_pasien_asesmen) && v.value == 'OTHER'}">
                                    <span>{{ v.label }}</span>
                                    <div v-if="checkCheckedValue(v.value, row.reaksi_pasien_asesmen)" class="ml-3">
                                    <template v-if="v.value == 'OTHER'">
                                        <b-form-textarea rows="3" cols="50" v-model="v.note" />
                                        <VValidate name="Catatan" v-model="v.note" :vid="`mrka-${k}`" rules="required" />
                                    </template>
                                    </div>
                                </div>
                                </b-form-checkbox>
                            </b-form-checkbox-group>

                            <VValidate name="Asesmen Informasi" :vid="`ai_reaksi_pasien`" :value="(row.reaksi_pasien_asesmen||[]).length > 0 ? true : null" :rules="mrValidation.reaksi_pasien_asesmen" />
                            </div>

                            <div class="form-group mt-2">
                            <label>Masalah keperawatan</label>
                            <b-form-checkbox-group v-model="row.reaksi_pasien_keperawatan" name="`reaksi_pasien_keperawatan`">
                                <b-form-checkbox v-for="(v, k) in $parent.mrReaksiPasienKeperawatan" :key="k" :value="v" :id="`reaksi_pasien_keperawatan${k}`" class="mb-2">
                                <div :class="{'d-flex': checkCheckedValue(v.value, row.reaksi_pasien_keperawatan) && v.value == 'OTHER'}">
                                    <span>{{ v.label }}</span>
                                    <div v-if="checkCheckedValue(v.value, row.reaksi_pasien_keperawatan)" class="ml-3">
                                    <template v-if="v.value == 'OTHER'">
                                        <b-form-textarea rows="3" cols="50" v-model="v.note" />
                                        <VValidate name="Catatan" v-model="v.note" :vid="`mkd-${k}`" rules="required" />
                                    </template>
                                    </div>
                                </div>
                                </b-form-checkbox>
                            </b-form-checkbox-group>

                            <VValidate name="Masalah Keperawatan" :vid="'mk_reaksi_pasien'" :value="(row.reaksi_pasien_keperawatan||[]).length > 0 ? true : null" :rules="mrValidation.reaksi_pasien_keperawatan" />
                            </div>
                        </div>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="card">
                        <div class="card-header bg_head_panel">
                            <h6 class="card-title font-weight-semibold">6.4 Reaksi keluarga atas penyakitnya</h6>
                        </div>
                        <div class="card-body">
                            <div class="form-group mt-2">
                            <label>Asesmen informasi</label>
                            <b-form-checkbox-group v-model="row.reaksi_keluarga_asesmen" name="`reaksi_keluarga_asesmen`">
                                <b-form-checkbox v-for="(v, k) in $parent.mrReaksiKeluargaAsesmen" :key="k" :value="v" :id="`reaksi_keluarga_asesmen${k}`" class="mb-2">
                                <div :class="{'d-flex': checkCheckedValue(v.value, row.reaksi_keluarga_asesmen) && v.value == 'OTHER'}">
                                    <span>{{ v.label }}</span>
                                    <div v-if="checkCheckedValue(v.value, row.reaksi_keluarga_asesmen)" class="ml-3">
                                    <template v-if="v.value == 'OTHER'">
                                        <b-form-textarea rows="3" cols="50" v-model="v.note" />
                                        <VValidate name="Catatan" v-model="v.note" :vid="`mrka-${k}`" rules="required" />
                                    </template>
                                    </div>
                                </div>
                                </b-form-checkbox>
                            </b-form-checkbox-group>

                            <VValidate name="Asesmen Informasi" :vid="`ai_reaksi_keluarga`" :value="(row.reaksi_keluarga_asesmen||[]).length > 0 ? true : null" :rules="mrValidation.reaksi_keluarga_asesmen" />
                            </div>

                            <div class="form-group mt-2">
                            <label>Masalah keperawatan</label>
                            <b-form-checkbox-group v-model="row.reaksi_keluarga_keperawatan" name="`reaksi_keluarga_keperawatan`">
                                <b-form-checkbox v-for="(v, k) in $parent.mrReaksiKeluargaKeperawatan" :key="k" :value="v" :id="`reaksi_keluarga_keperawatan${k}`" class="mb-2">
                                <div :class="{'d-flex': checkCheckedValue(v.value, row.reaksi_keluarga_keperawatan) && v.value == 'OTHER'}">
                                    <span>{{ v.label }}</span>
                                    <div v-if="checkCheckedValue(v.value, row.reaksi_keluarga_keperawatan)" class="ml-3">
                                    <template v-if="v.value == 'OTHER'">
                                        <b-form-textarea rows="3" cols="50" v-model="v.note" />
                                        <VValidate name="Catatan" v-model="v.note" :vid="`mkd-${k}`" rules="required" />
                                    </template>
                                    </div>
                                </div>
                                </b-form-checkbox>
                            </b-form-checkbox-group>

                            <VValidate name="Masalah Keperawatan" :vid="'mk_reaksi_keluarga'" :value="(row.reaksi_keluarga_keperawatan||[]).length > 0 ? true : null" :rules="mrValidation.reaksi_keluarga_keperawatan" />
                            </div>
                        </div>
                        </div>
                    </div>

                    </div>
                </div>
                </div>
            </div>

            <div class="col-md-6">
                <div class="card">
                <div class="card-header bg-info">
                    <h5 class="card-title font-weight-semibold">7. Kebutuhan dukungan atau kelonggaran pelayanan
                    bagi pasien dan keluarga</h5>
                </div>
                <div class="card-body">
                    <b-form-checkbox-group v-model="row.kebutuhan_dukungan" name="`kebutuhan_dukungan`">
                    <b-form-checkbox v-for="(v, k) in $parent.mrKebutuhanDukungan" :key="k" :value="v" :id="`kebutuhan_dukungan${k}`" class="mb-2">
                        <div :class="{'d-flex': checkCheckedValue(v.value, row.kebutuhan_dukungan) && v.value == 'OTHER'}">
                        <span>{{ v.label }}</span>
                        <div v-if="checkCheckedValue(v.value, row.kebutuhan_dukungan)" class="ml-3">
                            <template v-if="v.value == 'OTHER'">
                            <b-form-textarea rows="3" cols="50" v-model="v.note" />
                            <VValidate name="Catatan" v-model="v.note" :vid="`mkd-${k}`" rules="required" />
                            </template>
                        </div>
                        </div>
                    </b-form-checkbox>
                    </b-form-checkbox-group>

                    <VValidate name="Kebutuhan dukungan" :value="(row.kebutuhan_dukungan||[]).length > 0 ? true : null" :rules="mrValidation.kebutuhan_dukungan" />
                </div>
                </div>
            </div>

            <div class="col-md-6">
                <div class="card">
                <div class="card-header bg-info">
                    <h5 class="card-title font-weight-semibold">8. Kebutuhan alternatif pelayanan bagi pasien dan
                    keluarga</h5>
                </div>
                <div class="card-body">
                    <b-form-checkbox-group v-model="row.kebutuhan_alternatif" name="`kebutuhan_alternatif`">
                    <b-form-checkbox v-for="(v, k) in $parent.mrKebutuhanAlternatif" :key="k" :value="v" :id="`kebutuhan_alternatif${k}`" class="mb-2">
                        <div :class="{'d-flex': checkCheckedValue(v.value, row.kebutuhan_alternatif) && v.value == 'OTHER'}">
                        <span>{{ v.label }}</span>
                        <div v-if="checkCheckedValue(v.value, row.kebutuhan_alternatif)" class="ml-3">
                            <template v-if="v.value == 'OTHER'">
                            <b-form-textarea rows="3" cols="50" v-model="v.note" />
                            <VValidate name="Catatan" v-model="v.note" :vid="`mka-${k}`" rules="required" />
                            </template>
                        </div>
                        </div>
                    </b-form-checkbox>
                    </b-form-checkbox-group>

                    <VValidate name="Kebutuhan alternatif" :value="(row.kebutuhan_alternatif||[]).length > 0 ? true : null" :rules="mrValidation.kebutuhan_alternatif" />
                </div>
                </div>
            </div>

            <div class="col-md-6">
                <div class="card">
                <div class="card-header bg-info">
                    <h5 class="card-title font-weight-semibold">9. Faktor resiko bagi keluarga yang ditinggalkan
                    </h5>
                </div>
                <div class="card-body">
                    <div class="form-group mt-2">
                    <label>Asesmen informasi</label>
                    <b-form-checkbox-group v-model="row.resiko_keluarga_asesmen" name="`resiko_keluarga_asesmen`">
                        <b-form-checkbox v-for="(v, k) in $parent.mrResikoKeluargaAsesmen" :key="k" :value="v" :id="`resiko_keluarga_asesmen${k}`" class="mb-2">
                        <div :class="{'d-flex': checkCheckedValue(v.value, row.resiko_keluarga_asesmen) && v.value == 'OTHER'}">
                            <span>{{ v.label }}</span>
                            <div v-if="checkCheckedValue(v.value, row.resiko_keluarga_asesmen)" class="ml-3">
                            <template v-if="v.value == 'OTHER'">
                                <b-form-textarea rows="3" cols="50" v-model="v.note" />
                                <VValidate name="Catatan" v-model="v.note" :vid="`mrka-${k}`" rules="required" />
                            </template>
                            </div>
                        </div>
                        </b-form-checkbox>
                    </b-form-checkbox-group>

                    <VValidate name="Asesmen Informasi" :vid="`ai_resiko_keluarga`" :value="(row.resiko_keluarga_asesmen||[]).length > 0 ? true : null" :rules="mrValidation.resiko_keluarga_asesmen" />
                    </div>

                    <div class="form-group mt-2">
                    <label>Masalah keperawatan</label>
                    <b-form-checkbox-group v-model="row.resiko_keluarga_keperawatan" name="`resiko_keluarga_keperawatan`">
                        <b-form-checkbox v-for="(v, k) in $parent.mrResikoKeluargaKeperawatan" :key="k" :value="v" :id="`resiko_keluarga_keperawatan${k}`" class="mb-2">
                        <div :class="{'d-flex': checkCheckedValue(v.value, row.resiko_keluarga_keperawatan) && v.value == 'OTHER'}">
                            <span>{{ v.label }}</span>
                            <div v-if="checkCheckedValue(v.value, row.resiko_keluarga_keperawatan)" class="ml-3">
                            <template v-if="v.value == 'OTHER'">
                                <b-form-textarea rows="3" cols="50" v-model="v.note" />
                                <VValidate name="Catatan" v-model="v.note" :vid="`mkd-${k}`" rules="required" />
                            </template>
                            </div>
                        </div>
                        </b-form-checkbox>
                    </b-form-checkbox-group>

                    <VValidate name="Masalah Keperawatan" :vid="'mk_resiko_keluarga'" :value="(row.resiko_keluarga_keperawatan||[]).length > 0 ? true : null" :rules="mrValidation.resiko_keluarga_keperawatan" />
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>
        </div>
        <div class="card-footer">
        <div class="text-right">
            <button type="button" class="btn" @click="$router.back()">Kembali</button>
            <button type="submit" class="btn btn-primary">Submit <i class="icon-paperplane ml-2"></i></button>
        </div>
        </div>
    </b-form>
  </validation-observer>
</div>
</template>

<script>
import $ from 'jquery'
const _ = global._

import Gen from '@/libs/Gen.js'
import Formatter from '@/libs/Formatter.js'
import Config from '@/libs/Config.js'
const moment = require('moment')

export default {
  data() {
    return {
      rowData: {},
      isMonitoring: false
    }
  },
  components: {},
  computed: {
    isParent() {
      return this.$parent
    },
    row() {
      return this.$parent.row
    },
    mrValidation() {
      return this.$parent.mrValidation
    },

    Config() {
      return Config
    },
    Formatter() {
      return Formatter
    },
  },
  methods: {
    checkCheckedValue(value, data) {
      return data.map(dt => dt.value).indexOf(value) >= 0
    },

    doSubmit() {
      this.$refs['VForm'].validate().then(success => {
        if (!success) return false

        this.$swal.fire({
          icon: "warning",
          title: "<h2 class='font-weight-bold'>Sudah Yakin Dengan Data yang Diisi?</h2>",
          html: `
            <span style="font-size: 16px">
              Pastikan untuk mengisikan data yang sesuai, karena data yang sudah disubmit sudah tidak dapat diubah
            </span>              
          `,

          showCancelButton: true,
          cancelButtonText: "Cek Ulang",
          confirmButtonText: "Lanjutkan & Submit"
        }).then(button => {
          if (button.isConfirmed) {
            const payload = {
              ...this.row,
              id_registrasi: this.$route.params.pageSlug
            }
            this.$swal.fire({
              title: 'Loading...',
              showConfirmButton: false,
              allowOutsideClick: false,
              didOpen: () => {
                this.$swal.showLoading()
              }
            })

            Gen.apiRest(
              "/do/" + this.$parent.modulePage, {
                data: {
                  ...payload,
                  type: this.$route.query.kajianNo ? 'update' : 'add'
                }
              },
              "POST"
            ).then(resp => {
              this.$swal.close()
              this.$swal({
                title: resp.data.message,
                icon: 'success',
                confirmButtonText: 'Ok'
              }).then(result => {
                if (result.value) {
                  this.$router.push({
                    name: this.modulePage
                  }).catch(() => {})
                }
              })
            }).catch(() => {
              this.$swal.close()

              return this.$swal({
                icon: 'error',
                text: 'Terjadi Kesalahan Yang Tidak Diketahui'
              })
            })
          }
        })
      })
    }
  },
  watch: {}
}
</script>
